const IMAGES = {
  logo: require('./logo.png'),
  express_logo: require('./im-express-badge.svg'),
  logo_secondary: require('./logo-secondary.png'),
  storage_box: require('./home/pic1.png'),
  research: require('./home/pic2.png'),
  bullet_one: require('./home/bullet-1.png'),
  bullet_two: require('./home/bullet-2.png'),
  bullet_three: require('./home/bullet-3.png'),
  recurring_shred: require('./recurring_shred.png'),
  one_time_shred: require('./oneShredIconSrc.png'),
  boxIcon: require('./home/box-icon.svg'),
  shredIcon: require('./home/shred-icon.svg'),
  calenderIcon: require('./home/calender-icon.svg'),
  boxOnHoldIcon: require('./home/box-on-hold.svg'),
  certificateIcon: require('./home/certificate-icon.svg'),
  creditCardIcon: require('./payment_card.svg'),
  bankAccIcon: require('./payment_bank.svg'),
  reccuring_shred: require('./irm-shred-bin.png'),
  reccuring_shred_white: require('./irm-shred-bin-white.svg'),
  page_not_found: require('./error.png'),
  page_loading: require('./page_loading_icon.svg'),
  creditCardPayment: require('./credit-debit-card-button.png'),
  bankPayment: require('./bank-payment-button.png'),
  googlePay: require('./GooglePay.svg'),
  mfa_icon: require('./mfa_icon.png'),
  notification_icon: require('./notification_icon.png'),
  payment_edit: require('./payment_method_edit.svg'),
  payment_remove: require('./payment_method_remove.svg'),
  credit_card_icon: require('./credit_card_icon.svg'),
  bank_account_icon: require('./bank_account_icon.svg'),
  gpay_icon: require('./gpay_icon.png'),
  add_icon: require('./add_icon.svg'),
  back_arrow_left: require('./arrow-left.svg'),
  warning_icon: require('./warning-icon.svg'),
  payment_method_success_icon: require('./payment_method_success_icon.svg'),
  warning_icon_triangle: require('./warning_orange_icon.svg'),
};

export default IMAGES;
