import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import Interstate from '../font/Interstate-Regular.woff2';
import Geometria from '../font/Geometria-Light.woff2';

const InterstateFont = {
  fontFamily: 'Interstate',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${Interstate}) format('woff2')
  `,
};

const GeometriaFont = {
  fontFamily: 'Geometria',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    url(${Geometria}) format('woff2')
  `,
};

let theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#65b8df',
      contrastText: '#fefefe',
      light: 'rgba(85, 151, 205, 0.1)',
    },
    secondary: {
      main: '#3e3e3e',
      contrastText: '#fefefe',
    },
    text: {
      primary: '#3e3e3e',
      secondary: '#6b6b6b',
      softPrimary: '#5597cd',
      disabled: '#999',
    },
    background: {
      paper: '#fefefe',
      default: '#fefefe',
    },
    divider: '#dddddd',
    boxShadow: {
      big: '0px 50px 100px 0px rgb(17 17 45 / 49%)',
    },
  },
  typography: {
    fontFamily: ["'Interstate'", "'Open Sans'"],
    fontSize: 14,
    fontWeightBold: 600,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [InterstateFont, GeometriaFont],
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontWeight: 500,
        fontSize: '1rem',
      },
      outlined: {
        border: '1.5px solid',
      },
      text: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 0.9,
      },
    },
    MuiBackdrop: {
      root: {
        background: 'rgba(0, 0, 0, 0.3)',
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
