import React, { useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import ContactUsForm from '../Forms/ContactUsForm';
import { Grid, Link, Typography } from '@material-ui/core';
import DividerWithText from '../DividerWithText';
import { MODAL_INFO, MODAL_STATES, ROUTES } from '../../data/constants';
import ConfirmationModal from './ConfirmationModal';
import { useEmail } from '../../hooks/useEmail';
import useModal from '../../hooks/useModal';
import { SUPPORT_PHONE, PERMISSIONS } from '../../constants';
import { useAccount } from '../../hooks/useAccount';
import Loader from '../../common/Loader';
import { useTranslation } from 'react-i18next';
import { MAILS } from '../../utils/mails';

const ContactUsModal = () => {
  const classes = useStyles();
  const { postEmail } = useEmail();
  const { open, closeModal, defaultFormValue } = useModal();
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState(MODAL_STATES.main);
  const { isPermissionAvailable } = useAccount();
  const { t } = useTranslation();

  // Reset modal state
  useEffect(() => {
    open && setModalState(MODAL_STATES.main);
  }, [open]);

  /**
   * Creates email object (for both customer and rep) in Firestore.
   */
  const handleSubmit = async (values) => {
    setLoading(true);
    Promise.all([
      postEmail(MAILS.contactSupportMail(values)),
      postEmail(MAILS.contactSupportSFMail(values)),
    ])
      .then(() => {
        setModalState(MODAL_STATES.success);
      })
      .catch((err) => {
        console.log('Failed to send email', err);
        setModalState(MODAL_STATES.failure);
      })
      .finally(() => setLoading(false));
  };

  return (
    <ConfirmationModal
      open={open}
      type={modalState}
      onClose={closeModal}
      fullWidth={true}
      maxWidth={modalState === MODAL_STATES.main ? 'md' : 'sm'}
      successProps={MODAL_INFO.contactUs.success}
      failureProps={MODAL_INFO.contactUs.failure}
    >
      <DialogTitle>{t('contact_us_btn')}</DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            {loading ? (
              <Loader />
            ) : (
              <ContactUsForm
                defaultFormValue={defaultFormValue}
                onSubmit={handleSubmit}
                onCancel={closeModal}
              />
            )}
          </Grid>

          <DividerWithText label="OR" />

          <Grid item className={classes.centerText}>
            <Typography variant={'body2'} color={'textSecondary'}>
              {t('contact_us_modal_contact_phone_text')}{' '}
              <strong>{SUPPORT_PHONE}</strong>
            </Typography>
          </Grid>

          {isPermissionAvailable(PERMISSIONS.faqs) && (
            <Grid item className={classes.centerText}>
              <Typography variant={'body2'} color={'textSecondary'}>
                {t('contact_us_modal_link_to_more_info')}{' '}
                <Link
                  href={ROUTES.faqs.path}
                  color={'textSecondary'}
                  underline={'always'}
                >
                  {t('contact_us_modal_faq_link')}
                </Link>
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </ConfirmationModal>
  );
};

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    paddingBottom: theme.spacing(4),
  },
  fullWidth: {
    width: '100%',
  },
  centerText: {
    textAlign: 'center',
  },
  semibold: {
    fontWeight: 500,
  },
}));

export default ContactUsModal;
