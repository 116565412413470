import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from './useAuth';
import {
  getAccountInfo,
  getActiveAccount,
  getProductBundles,
  getSettings,
  getShippingAddress,
} from '../api/firestoreFunctions';
import { FS_SETTENIGS_COLLECTION, USER_ROLES } from '../constants';
import { getAccountBundles } from '../api/firestoreFunctions';

export const AccountContext = createContext({});

/**
 * Custom hook for providing Firebase account info to consuming components.
 */
export const useAccount = () => useContext(AccountContext);

export const AccountProvider = ({ children }) => {
  const defaultAccount = useDefaultAccount();
  return (
    <AccountContext.Provider value={defaultAccount}>
      {children}
    </AccountContext.Provider>
  );
};

AccountProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useDefaultAccount = () => {
  const { user, logout } = useAuth();
  const [account, setAccount] = useState(undefined);
  const [address, setAddress] = useState(undefined);
  const [activeBundles, setActiveBundles] = useState(undefined);
  const [bundleOptions, setBundleOptions] = useState(undefined);
  const [userRole, setUserRole] = useState(USER_ROLES.limited);
  const [error, setError] = useState(undefined);
  const [userPermissions, setUserPermissions] = useState([]);
  const [commonSettings, setCommonSettings] = useState(undefined);
  const [accountBundleIds, setAccountBundleIds] = useState(undefined);

  useEffect(() => {
    const fetchAccountInfo = async () => {
      // Reset account & bundle on account change
      if (account || activeBundles) {
        setAccount(undefined);
        setActiveBundles(undefined);
      }
      try {
        // Fetch any account info here
        getAccount();
      } catch (err) {
        console.error('Failed to fetch customer account info', err);
        setError(err);

        if (['auth/id-token-expired', 'unauthenticated'].includes(err.code)) {
          logout();
        }
      }
    };

    user && !user.user.isAdmin && fetchAccountInfo();
    checkUserRole();
  }, [user]);

  useEffect(() => {
    /**
     * Fetches bundle information for the account associated with the logged-in user.
     */
    if (user && !user.user.isAdmin) {
      const accountId = user.user.account._id;
      const unsubscribeSummaryListner = getActiveAccount(accountId).onSnapshot(
        async (querySnapshot) => {
          // Get bundle metadata
          const accountBundles = [];
          querySnapshot.forEach((doc) => {
            if (!doc.data().isCancelled) {
              accountBundles.push({
                id: doc.id,
                firebaseBundleId: doc.id,
                ...doc.data(),
              });
            }
          });
          setActiveBundles(accountBundles);
          fetchStorageBundleOptions(accountBundles);
        },
        (err) => {
          console.error('Failed to retrieve bundle info from Firebase', err);
          setError(err);
          if (['auth/id-token-expired', 'unauthenticated'].includes(err.code)) {
            logout();
          }
        }
      );
      return () => {
        unsubscribeSummaryListner();
      };
    }
  }, [user]);

  useEffect(() => {
    /**
     * Fetches product information (bundle list, associated service address and billing address ) for the account associated with the logged-in user.
     */
    const fetchBundleDetails = async () => {
      try {
        const allBundles = await getAccountBundles(account.id);
        const bundleIds = [];
        allBundles &&
          allBundles.forEach((bundle) => {
            if (
              bundle.data().bundleTypeId &&
              !bundleIds.includes(bundle.data().bundleTypeId)
            ) {
              bundleIds.push(bundle.data().bundleTypeId);
            }
          });
        setAccountBundleIds(bundleIds);
      } catch (err) {
        console.error('Failed to fetch customer bundle info', err);
        setError(err);
      }
    };

    account && fetchBundleDetails();
  }, [account]);
  /**
   * Fetch bundle options from active storage bundle
   *
   * @param {Array} activeBundles
   */
  const fetchStorageBundleOptions = async (activeBundles) => {
    const settings = await getSettings(FS_SETTENIGS_COLLECTION.common);
    const { storageBundles } = settings.data();
    setCommonSettings(settings.data());
    const activeStorageBundle = activeBundles.find((bundle) =>
      storageBundles.includes(bundle.bundleTypeId)
    );
    if (activeStorageBundle) {
      const products = await getProductBundles(
        activeStorageBundle.bundleTypeId
      );
      setBundleOptions(products.data());
    }
  };

  /**
   * Reload the account data
   */
  const refreshAccount = async () => {
    const accInfo = await getAccountInfo(user);
    setAccount(accInfo.data());
  };

  /**
   * Fetches account information for the logged-in user
   */
  const getAccount = async () => {
    try {
      const accountData = await Promise.all([
        getAccountInfo(user),
        getSettings(FS_SETTENIGS_COLLECTION.permissions),
      ]);
      const accInfo = accountData[0].data();
      const userPermissions = accountData[1].data();
      const shippingCode = accInfo.defaultAddressCodes?.SHIP;
      const shippingData = await getShippingAddress(user, shippingCode);
      const addressInfo = shippingData.data();
      setUserPermissions(userPermissions.permissions);
      setAddress(addressInfo);
      setAccount(accInfo);
    } catch (err) {
      console.error('Failed to fetch account info from Firebase', err.message);
      setError(err);
    }
  };

  /**
   * check user permission to view component or perform an action
   */
  const isPermissionAvailable = (name) => {
    // If user has admin role then give all the permissions
    if (userRole === USER_ROLES.admin || user.user.isAdmin) {
      return true;
    }
    // If user has no admin role then check the permission
    const permission = userPermissions.filter((item) => item.id === name);
    if (permission.length > 0) {
      const { requiresApproval } = permission[0];
      if (requiresApproval) {
        return false;
      }
      return true;
    }
    return false;
  };

  /**
   * returns the user role
   */
  const checkUserRole = () => {
    const userRoles = user.user.roles;
    if (userRoles?.length > 0) {
      const role = userRoles[0];
      setUserRole(role);
    }
  };

  return {
    address,
    account,
    activeBundles,
    accountBundleIds,
    bundleOptions,
    commonSettings,
    error,
    userRole,
    isPermissionAvailable,
    refreshAccount,
  };
};
