import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useAuth } from '../../hooks/useAuth';
import { useAccount } from '../../hooks/useAccount';
import Loader from '../../common/Loader';
import { useTranslation } from 'react-i18next';

const MESSAGE_CHAR_LIMIT = 250;

const ContactUsForm = ({ onSubmit, onCancel, defaultFormValue }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { account } = useAccount();
  const { t } = useTranslation();

  const [reason, setReason] = useState(
    defaultFormValue.reason ? defaultFormValue.reason : ''
  );
  const [message, setMessage] = useState('');
  const menuItems = [
    'Billing & payment',
    'Current plan & upgrades',
    'Shred appointments',
    'Box pick-up appointments',
    'Indexing & inventory listing',
    'IoD / digital file scan request',
    'Box retrievals',
    'Admin / Permissions',
    'Renewal / Cancellation',
    'Other',
  ];
  const handleSubmit = (e) => {
    e.preventDefault();

    const values = {
      name: user.user.name,
      email: user.user.email,
      customerId: account.sfAccountId,
      reason,
      message,
    };

    onSubmit(values);
  };

  const onMessageChange = (e) => {
    const newMessage = e.target.value;
    if (newMessage.length <= MESSAGE_CHAR_LIMIT) {
      setMessage(newMessage);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      {!account ? (
        <Loader />
      ) : (
        <Grid
          container
          direction={'column'}
          alignItems={'flex-start'}
          spacing={2}
        >
          <Grid item className={classes.fullWidth}>
            <Grid
              container
              direction={'row'}
              alignItems={'flex-start'}
              spacing={2}
            >
              <Grid item xs={12} sm={6}>
                <FormControl variant="filled" fullWidth required={true}>
                  <InputLabel>Reason for contact</InputLabel>
                  <Select
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  >
                    {menuItems.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box className={classes.customerInfoBox}>
                  <Grid container direction={'column'} spacing={1}>
                    <Grid item>
                      <Typography variant={'body2'} color={'primary'}>
                        {t('name_label')}
                      </Typography>
                      <Typography variant={'body2'}>
                        {user.user.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={'body2'} color={'primary'}>
                        {t('email_address_label')}
                      </Typography>
                      <Typography variant={'body2'}>
                        {user.user.email}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={'body2'} color={'primary'}>
                        {t('customer_id_label')}
                      </Typography>
                      <Typography variant={'body2'}>
                        {account.sfAccountId}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.fullWidth}>
            <FormControl variant="filled" fullWidth>
              <TextField
                variant={'filled'}
                label={'Message'}
                value={message}
                multiline
                minRows={4}
                maxRows={4}
                onChange={onMessageChange}
                placeholder="Provide additional thoughts or questions for our team here"
                helperText={`${message.length}/250`}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                required={true}
              />
            </FormControl>
          </Grid>

          <Grid item className={classes.fullWidth}>
            <Grid container spacing={2}>
              <Grid item>
                <Button variant={'contained'} color="primary" type={'submit'}>
                  {t('submit_btn')}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={onCancel}>
                  {t('cancel_btn')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#f5f5f5',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 7,
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
  },
  fullWidth: {
    width: '100%',
  },
  customerInfoBox: {
    backgroundColor: '#f5f5f5',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 7,
    padding: theme.spacing(1),
  },
  helperText: {
    textAlign: 'right',
  },
}));

ContactUsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultFormValue: PropTypes.object,
};

export default ContactUsForm;
