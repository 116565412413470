import { v4 as uuidv4, v1 as uuidv1 } from 'uuid';

export const OKTA_ORG_URL = process.env.REACT_APP_OKTA_TENANT;
export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
export const CYBERSOURCE_URL = process.env.REACT_APP_CYBERSOURCE_URL;
export const GATEWAY_URI = process.env.REACT_APP_API_GATEWAY;
export const GATEWAY_HOST = `${GATEWAY_URI}/api/v1`;
export const API_TIMEOUT = 60000;
export const SESSION_TIMEOUT = 3600000;
export const APP_IDLE_TIMEOUT =
  process.env.REACT_APP_ENV === 'sandbox' ? 60 : 15; // App idle time: dev 60 mins, other env 15 mins
export const OKTA_AUTH_HEADER = 'okta-authorization';
export const BEARER_TOKEN = 'Bearer';
export const REDIRECT_URI = window.location.origin;

const SCOPES = 'CIAM_TENANT openid';
const RESPONSE_TYPE = 'token id_token';
const NONCE = uuidv1();
const APP_STATE = uuidv4();
export const OKTA_LOGIN_URI = `${OKTA_ORG_URL}/authorize?response_type=${RESPONSE_TYPE}&client_id=${OKTA_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=${APP_STATE}&nonce=${NONCE}&scope=${SCOPES}`;

export const APP_NAME = 'Iron Mountain Express';
export const APP_EMAILS = {
  support: 'support@express.ironmountain.com',
  info: 'noreply@okta.com',
};

export const INV_TYPE = {
  box: 'box',
  file: 'file',
};

export const ORDER_STATUS = {
  created: 'Created',
  inProgress: 'In Progress',
  completed: 'Completed',
  cancelled: 'Cancelled',
  partial: 'Partial download',
};

export const INV_STATUS = {
  OUT_OF_STORAGE_STATUS: 'Out Of Storage',
  IN_STORAGE_STATUS: 'In Storage',
  DOC_NOT_RECEIVED_STATUS: 'Not Received',
};

export const CYBERSOURCE_REDIRECT_URL = `${CYBERSOURCE_URL}/services/sbc/paymentservice.aspx?TransationType=sale`;

export const OKTA_ERROR_CODES = {
  access_denied: 'access_denied',
};

export const SUPPORT_PHONE = '888-703-8127';
export const FLEX_KEY_URL = 'sbc/api/payment/getflexkey?host=';

export const FS_SETTENIGS_COLLECTION = {
  common: 'common',
  permissions: 'rbac',
  tms: 'tms',
  products: 'products',
};

export const USER_ROLES_OPTIONS = [
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'standard',
    label: 'Standard',
  },
  {
    value: 'limited',
    label: 'Limited',
  },
];

export const STATUS_OPTIONS = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'inactive',
    label: 'Inactive',
  },
];
export const USER_ROLES = {
  admin: 'admin',
  standard: 'standard',
  limited: 'limited',
};

export const USER_STATUS = {
  active: 'active',
  expired: 'inviteExpired',
  pending: 'invitePending',
  inactive: 'inactive',
  passwordReset: 'passwordReset',
  passwordLinkExpired: 'passwordLinkExpired',
};

export const USER_MGT_ACTIONS = {
  resendInvite: 'resendInvite',
  reactivate: 'reactivate',
  resetPassword: 'resetPassword',
  removeUser: 'removeUser',
};

export const ACCOUNT_STATUS = {
  active: 'active',
  inactive: 'inactive',
  onHold: 'onHold',
  deliquent: 'deliquent',
};

export const PERMISSIONS = {
  contactUs: 'contactUs',
  faqs: 'faqs',
  markBoxesConfidential: 'markBoxesConfidential',
  viewBoxesAndFiles: 'viewBoxesAndFiles',
  searchDocuments: 'searchDocuments',
  requestBoxRetrieval: 'requestBoxRetrieval',
  requestIoD: 'requestIoD',
  downloadIoDFile: 'downloadIoDFile',
  returnBoxToStorage: 'returnBoxToStorage',
  requestOrderNewBoxes: 'requestOrderNewBoxes',
  authorizeBoxDestruction: 'authorizeBoxDestruction',
  uploadIndexingFile: 'uploadIndexingFile',
  downloadIndexingFile: 'downloadIndexingFile',
  seeUpcomingAndPastOrderDetails: 'seeUpcomingAndPastOrderDetails',
  viewRemainingRequestsTopCard: 'viewRemainingRequestsTopCard',
  viewOverageChargesTopCard: 'viewOverageChargesTopCard',
  viewBillingCycleTopCard: 'viewBillingCycleTopCard',
  editSchedulingEligibleService: 'editSchedulingEligibleService',
  hasAccountInformationPage: 'hasAccountInformationPage',
  viewPlanDetailsCoreVsBasic: 'viewPlanDetailsCoreVsBasic',
  changeSubscriptionPlanLimits: 'changeSubscriptionPlanLimits',
  changePassword: 'changePassword',
  viewCustomerID: 'viewCustomerID',
  manageUsersAddRremove: 'manageUsersAddRremove',
  changeUsersPermission: 'changeUsersPermission',
  editUserDetails: 'editUserDetails',
  userActivityLog: 'userActivityLog',
  authorizeAdditionalCharges: 'authorizeAdditionalCharges',
  viewBillingHistory: 'viewBillingHistory',
  downloadInvoices: 'downloadInvoices',
  managePaymentMethods: 'managePaymentMethods',
};

export const PERMISSIONS_TABLE = [
  {
    data: 'Document Center',
    isTitle: true,
    isHeader: true,
    titles: ['Admin', 'Standard', 'Limited'],
  },
  {
    data: 'View confidential documents',
    permissions: [1, 1, 0],
    dark: true,
  },
  {
    data: 'View non-confidential documents',
    permissions: [1, 1, 1],
  },
  {
    data: 'Label boxes as confidential',
    permissions: [1, 0, 0],
    dark: true,
  },
  {
    data: 'Service requests',
    isTitle: true,
  },
  {
    data: 'Request an Image on Demand (no admin approval)',
    permissions: [1, 1, 0],
    dark: true,
  },
  {
    data: 'Request a Physical box retrieval (no admin approval)',
    permissions: [1, 1, 0],
  },
  {
    data: 'Return a box to storage  (no admin approval)',
    permissions: [1, 1, 0],
    dark: true,
  },
  {
    data: 'Authorize box destruction',
    permissions: [1, 0, 0],
  },
  {
    data: 'Make requests on documents with Admin approval',
    permissions: [0, 0, 1],
    tooltip:
      'Limited users will be able to submit a document scan request, physical box retrieval request, and return box to storage request but will always require Admin approval for the request to be initiated.',
    dark: true,
  },
  {
    data: 'Submit requests that incur an additional charge',
    permissions: [1, 1, 0],
  },
  {
    data: 'Approve/deny requests from Limited users',
    permissions: [1, 0, 0],
    dark: true,
  },
  {
    data: 'View service activity',
    permissions: [1, 1, 1],
    tooltip:
      'Users will only be able to view service activity on the documents they have access to view. (i.e. a Limited user will not see service activity on confidential documents).',
  },
  {
    data: 'Account management',
    isTitle: true,
  },
  {
    data: 'Add/remove users',
    permissions: [1, 0, 0],
    dark: true,
  },
  {
    data: 'Adjust user permissions',
    permissions: [1, 0, 0],
  },
  {
    data: 'View user activity',
    permissions: [1, 0, 0],
    dark: true,
  },
  {
    data: 'View and manage account details',
    permissions: [1, 0, 0],
    tooltip:
      'Account details includes upgrading/downgrading your plan, editing your contact and payment information, and adjusting storage boxes and shred bins needed.',
  },
  {
    data: 'View billing history',
    permissions: [1, 1, 0],
    dark: true,
  },
  {
    data: 'Download invoices',
    permissions: [1, 1, 0],
  },
  {
    data: 'Manage payment methods',
    permissions: [1, 0, 0],
    dark: true,
  },
  {
    data: '',
    isFooter: true,
  },
];

export const PRODUCT_LIST = {
  basic: 'Basic',
  core: 'Core',
  shred: 'Shred',
  storage: 'Storage',
  storageShred: 'Storage & Shred',
  archivalStorage: 'Archival Storage',
};

export const PAYMENT_METHOD_CONTENT = {
  paymentList: 'paymentList',
  paymentMethodSelection: 'paymentMethodSelection',
  cardPayment: 'cardPayment',
  bankPayment: 'bankPayment',
  googlePay: 'googlePay',
  editCardPayment: 'editCardPayment',
  editBankPayment: 'editBankPayment',
};

export const PAYMENT_METHODS = {
  bank: 'CHECK',
  card: 'CARD',
  googlePay: 'GOOGLEPAY',
};

export const BANK_ACCOUNT_TYPE = [
  { value: '0', label: 'Select' },
  { value: 'C', label: 'Checking' },
  { value: 'S', label: 'Savings' },
  { value: 'X', label: 'Corporate checking' },
];

export const SIT_CHAT_CONFIG = {
  baseUrl: 'https://ironmountain--sit.my.salesforce.com',
  communityUrl:
    'https://sit-partners-ironmountain.cs159.force.com/irmpubliccommunity',
  chatID: '00D7X000000LAjQ',
  config: {
    baseLiveAgentContentURL:
      'https://c.la2-c1cs-ia5.salesforceliveagent.com/content',
    deploymentId: '5722H0000008Oba',
    buttonId: '5737X0000004DC6',
    baseLiveAgentURL: 'https://d.la2-c1cs-ia5.salesforceliveagent.com/chat',
    eswLiveAgentDevName:
      'EmbeddedServiceLiveAgent_Parent04I7X0000008ORWUA2_1793d08ea28',
    isOfflineSupportEnabled: false,
  },
};

export const UAT_CHAT_CONFIG = {
  baseUrl: 'https://ironmountain--uat.my.salesforce.com',
  communityUrl:
    'https://uat-partners-ironmountain.cs9.force.com/irmpubliccommunity',
  chatID: '00DK000000XKPy3',
  config: {
    baseLiveAgentContentURL:
      'https://c.la3-c1cs-dfw.salesforceliveagent.com/content',
    deploymentId: '5722H0000008Oba',
    buttonId: '5732H000000PBfK',
    baseLiveAgentURL: 'https://d.la3-c1cs-dfw.salesforceliveagent.com/chat',
    eswLiveAgentDevName:
      'EmbeddedServiceLiveAgent_Parent04I2H000000PAtQUAW_1795a791efa',
    isOfflineSupportEnabled: false,
  },
};

export const PROD_CHAT_CONFIG = {
  baseUrl: 'https://ironmountain.my.salesforce.com',
  communityUrl: 'https://partners-ironmountain.force.com/irmpubliccommunity',
  chatID: '00D80000000aP47',
  config: {
    baseLiveAgentContentURL:
      'https://c.la3-c1-ph2.salesforceliveagent.com/content',
    deploymentId: '5722H0000008Oba',
    buttonId: '5732H000000PBfK',
    baseLiveAgentURL: 'https://d.la3-c1-ph2.salesforceliveagent.com/chat',
    eswLiveAgentDevName:
      'EmbeddedServiceLiveAgent_Parent04I2H000000PAtQUAW_1795a791efa',
    isOfflineSupportEnabled: false,
  },
};

export const ADDRESS_TYPES = {
  ACCOUNT: 'ACCOUNT',
  BILL: 'BILL',
  SHIP: 'SHIP',
};

export const ACCOUNT_USER_TYPES = {
  CUSTOMER: 'CUSTOMER',
  ADMIN: 'ADMIN',
};

export const APP_TIME_ZONE = 'America/New_York';
